import React, {useState} from "react"
import "./Travel.scss"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MenuItem from '@mui/material/MenuItem';
import * as tMap from '../../../../store/stateStr/TravMap'
import * as pMap from "../../../../store/stateStr/PurchMap";
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {totCommHour} from "../../../Library/Utils/Utils";
import {withMedia} from "react-media-query-hoc";

const Travel = props => {
    const [fieldChanged, setFieldChanged] = useState(false)

    let macchine = null
    if (props.machines !== undefined) {
        macchine = props.machines.sortBy(m => m.get('plates')).map((option) => {
            return (
                <MenuItem key={option.get("id")} value={option.get("id")}>
                    {option.get("plates")}
                </MenuItem>
            );
        });
    }


    return (
        <div className="travel_v2">
            <div className={'subRow'} style={{marginTop: '10px', flex: 0}}>

                <TextField
                    id={"timeStart-" + props.travel.get(tMap.DbKey)}
                    label="Inizio"
                    type="time"
                    color={"secondary"}
                    variant="outlined"
                    size="small"
                    disabled={props.lockedData}
                    defaultValue={props.travel.get(tMap.Start)}
                    className={'timePicker'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    onChange={() => {
                        setFieldChanged(true)
                    }}
                    onBlur={(event) => {
                        if (!fieldChanged) return
                        setFieldChanged(false)
                        props.onPropsTravelChangeHandler(event.target.value, props.idx_day, props.idx_comm, props.idx_trav, tMap.Start, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))
                    }}
                />
                <TextField
                    id={"timeEnd-" + props.travel.get(tMap.DbKey)}
                    label="Fine"
                    type="time"
                    variant="outlined"
                    color={"secondary"}
                    margin="dense" size="small"
                    disabled={props.lockedData}
                    defaultValue={props.travel.get(tMap.End)}
                    className={'timePicker'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    onChange={() => {
                        setFieldChanged(true)
                    }}
                    onBlur={(event) => {
                        if (!fieldChanged) return
                        setFieldChanged(false)
                        props.onPropsTravelChangeHandler(event.target.value, props.idx_day, props.idx_comm, props.idx_trav, tMap.End, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))
                    }}
                />
            </div>

            <ToggleButtonGroup
                className={'autostrada'}
                value={props.travel.get('passenger')}
                disabled={props.lockedData}
                exclusive
                onChange={(event, newValue) => props.onPropsTravelChangeHandler(newValue, props.idx_day, props.idx_comm, props.idx_trav, 'passenger', props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))}
                color={"secondary"}
            >
                <ToggleButton value={true} aria-label="left aligned">
                    Passeeggero
                </ToggleButton>
            </ToggleButtonGroup>
            {!props.travel.get('passenger') &&
                <>
                    <div className={'subRow'}>
                        <TextField
                            id="standard-dense"
                            variant="outlined"
                            label="km"
                            className={'km textfield'}
                            margin="dense" size="small"
                            type="number"
                            color="secondary"
                            disabled={props.lockedData || props.travel.get('passenger')}
                            defaultValue={props.travel.get(tMap.KmAndata)}
                            onChange={() => {
                                setFieldChanged(true)
                            }}
                            onBlur={(event) => {
                                if (!fieldChanged) return
                                setFieldChanged(false)
                                props.onPropsTravelChangeHandler(parseInt(event.target.value), props.idx_day, props.idx_comm, props.idx_trav, tMap.KmAndata, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))
                            }}
                        />
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Macchina"
                            margin="dense" size="small"
                            className={'textfield'}
                            disabled={props.lockedData || props.travel.get('passenger')}
                            value={props.travel.get(tMap.Targa)}
                            onChange={(event) => props.onPropsTravelChangeHandler(event.target.value, props.idx_day, props.idx_comm, props.idx_trav, tMap.Targa, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))}
                            SelectProps={{MenuProps: {},}}
                            variant="outlined"
                            color="secondary"
                        >
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            {macchine}
                        </TextField>
                    </div>
                    <div className={'subRow'} style={{width: 'auto'}}>
                        <ToggleButtonGroup
                            className={'autostrada'}
                            value={props.travel.get(tMap.Autostrada)}
                            disabled={props.lockedData || props.travel.get('passenger')}
                            exclusive
                            onChange={(event, newValue) => props.onPropsTravelChangeHandler(newValue, props.idx_day, props.idx_comm, props.idx_trav, tMap.Autostrada, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))}
                            color={"secondary"}
                        >
                            <ToggleButton value={true} aria-label="left aligned">
                                Autostrada
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </>
            }
            <div className={'subRow'} style={{width: 'auto'}}>
                {
                    (props.media.genPc || props.media.tablet || props.media.mobile) &&
                    <Typography
                        style={{marginTop: '10px'}}>{`Tot. Ore ${Math.round(totCommHour(props.travel.get(tMap.Start), props.travel.get(tMap.End)) * 100) / 100}`}</Typography>
                }
            </div>
            <div style={{margin: '0 0 0 auto'}}>
                <Fab color="secondary" className={'circButton'}
                     disabled={props.lockedData}
                     onClick={() => props.AddTravel(props.idx_day, props.idx_comm, props.travel.get(pMap.Day), props.id_comm)}>
                    <AddIcon/>
                </Fab>
                <Fab color="secondary" className={'circButton'}
                     disabled={props.lockedData}
                     onClick={() => props.RemoveTravel(props.idx_day, props.idx_comm, props.idx_trav, props.travel.get(pMap.Day), props.id_comm, props.travel.get(pMap.DbKey))}>
                    <RemoveIcon/>
                </Fab>
            </div>
        </div>
    )

};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withMedia(Travel))

