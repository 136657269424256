import React, {PureComponent} from 'react';
import './App.css';
import ListaComm from "./components/Mng/Mng"
import Layout from "./Layout/Layout"
import WorkDataV2 from "./components/WorkList/WorkList"
import Auth from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import Settings from "./components/Settings/Settings";
import {connect} from "react-redux";
import AxiosManager from "./hoc/AxiosManager/AxiosManager";
import apiV2 from "./apiV2";
import {authWithToken} from "./store/actions/auth";
import CalendarView from "./components/CalendarView/CalendarView";
import ConnectionState from "./hoc/ConnectionState/ConnectionState";
import UsrComm from "./components/UsrComm/UsrComm";
import {BrowserRouter, Route} from "react-router-dom"
import {Redirect} from "react-router";
import {Map} from 'immutable'
import Notifier from "./Notifier/notifier";
import {SnackbarProvider} from "notistack";
import FdlMng from "./components/FdlMng/FdlMng";
import WdAgg from "./components/WdAgg/WdAgg";
import {green, red} from "@mui/material/colors";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import moment from "moment";
import 'moment/locale/it'
import {CLOSE_SNACKBAR, newNotification} from "./store/actions/action";
import WorkDataCompilation from "./components/WorkDataCompilation/WorkDataCompilation";
import Themes from "./components/Themes/Themes";
import ThemeManagement from "./components/Themes/ThemeManagement/ThemeManagement";
import WdFdlExport from "./components/WdFdlExport/WdFdlExport";
import WdExport from "./components/WdExport/WdExport";
import { setDefaultOptions } from 'date-fns'
import { it } from 'date-fns/locale'


class App extends PureComponent {

    state = {
        connectionState: 0,
    }


    componentDidMount() {
        this.props.authWithToken()

    }

    render() {
        moment.locale('it')
        setDefaultOptions({ locale: it })

        if (!this.props.isOnline && this.state.connectionState === 0) {
            const key = new Date().getTime() + Math.random()
            this.props.offlineNotification('Offline', {
                key: key,
                persist: true,
                variant: 'error',
                preventDuplicate: true
            })
            this.setState({connectionState: key})
        } else if (this.props.isOnline && this.state.connectionState !== 0) {
            this.props.closeOfflineNotification(this.state.connectionState)
            this.setState({connectionState: 0})
        }


        if (!this.props.userLogged && this.props.autoLogin !== 0 && !(window.location.pathname === '/SignUp' || window.location.pathname === '/SignIn')) {
            window.location.pathname = '/SignIn'
        }

        const theme = createTheme({
            overrides: {
                MuiStepIcon: {
                    root: {
                        '&$completed': {
                            color: '#2e7d32',
                        },
                        '&$active': {
                            color: '#4caf50',
                        },
                    },
                    active: {},
                    completed: {},
                },
            },
            components: {
                MuiPaper: {
                    // styleOverrides: {
                    //     root: {
                    //         backgroundImage: 'unset',
                    //     },
                    // },

                    variants: [
                        {
                            props: {transparent: true},
                            style: {
                                backgroundImage: 'unset',
                                backgroundColor: 'unset'

                            },
                        },
                    ],
                },
                MuiTypography: {
                    variants: [
                        {
                            props: {color: 'tertiary'},
                            style: {
                                color: this.props.theme.get('tertiaryColor') || '#FFC107'
                            },
                        },
                    ],
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: (theme) => ({
                            padding: '8px'
                        }), track: (theme) => ({
                            borderRadius: 22 / 2, '&:before, &:after': {
                                content: '""',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: 16,
                                height: 16,
                            }, '&:before': {
                                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                    theme.theme.palette.getContrastText(theme.theme.palette.primary.main),
                                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                                left: 12,
                            }, '&:after': {
                                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                    theme.theme.palette.getContrastText(theme.theme.palette.primary.main),
                                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                                right: 12,
                            },
                        })
                    }
                }
            },
            palette: {
                mode: "dark",
                primary: {
                    main: this.props.theme.get('primaryColor') || '#255b97',
                    // dark: this.props.theme.get('primaryColor') || '#255b97',
                    // light: this.props.theme.get('primaryColor') || '#255b97',
                },
                secondary: {
                    main: this.props.theme.get('secondaryColor') || '#00b0ff',
                    // dark: this.props.theme.get('secondaryColor') || '#00b0ff',
                    // light: this.props.theme.get('secondaryColor') || '#00b0ff',
                    // contrastText: '#FFF',
                },
                tertiary: {
                    main: this.props.theme.get('tertiaryColor') || '#FFC107'
                },
                // background: {
                //     default: '#202125',
                //     // contrastText: '#000',
                // },
                error: {
                    main: red[700],
                    dark: red[800],
                    light: red[600],
                },
                success: {
                    main: green[700],
                    dark: green[800],
                    light: green[600],
                },
                white: {
                    main: '#fafafa',
                },
                paper: {
                    default: '#007bff',

                },
                background: {
                    // default: '#0a1929',
                    // paper: 'rgba(58,58,58,0.2)'
                }
            },
            typography: {
                fontFamily: 'IBM Plex Mono'
            }
        });

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <SnackbarProvider preventDuplicate maxSnack={4} anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                    <Notifier/>
                    {this.props.autoLogin !== 0 ?
                        <BrowserRouter basename="/">
                            <div className="App">
                                {this.props.userLogged &&
                                    <Layout>
                                        <Route path="/" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <WorkDataV2/>}
                                        </Route>
                                        <Route path="/calendar_view/" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <CalendarView/>}
                                        </Route>
                                        <Route path="/commpers" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <UsrComm/>}
                                        </Route>
                                        <Route path="/comm" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <ListaComm/>}
                                        </Route>
                                        <Route path="/fdl" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <FdlMng/>}
                                        </Route>
                                        <Route path="/statistics" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <WdAgg/>}
                                        </Route>
                                        <Route path="/workdataCompilation" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <WorkDataCompilation/>}
                                        </Route>
                                        <Route path="/WdFdlExport" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <WdFdlExport/>}
                                        </Route>
                                        <Route path="/WdExport" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <WdExport/>}
                                        </Route>
                                        <Route path="/themes" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <Themes/>}
                                        </Route>
                                        <Route path="/themesManagement" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <ThemeManagement/>}
                                        </Route>
                                        <Route path="/settings" exact>
                                            {!this.props.userLogged ? <Redirect to="/SignIn"/> : <Settings/>}
                                        </Route>
                                    </Layout>
                                }

                                <Route path="/SignUp" exact>
                                    {this.props.userLogged ? <Redirect to="/"/> : <SignUp/>}
                                </Route>
                                <Route path="/SignIn" exact>
                                    {this.props.userLogged ? <Redirect to="/"/> : <Auth/>}
                                </Route>
                                {/*<Redirect to={"SignIn"}/>*/}
                            </div>
                        </BrowserRouter> :
                        // <ThemeProvider theme={theme}>
                        //     <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                        <div className={'boot'}>
                            <img src={'FogliOre.svg'} alt=""/>
                        </div>
                        //     </SnackbarProvider>
                        // </ThemeProvider>
                    }
                </SnackbarProvider>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = state => {
    return {
        userLogged: state.auth.userLogged,
        autoLogin: state.auth.autoLogin,
        beta: state.auth.user.getIn(["userData", "config", 'beta'], false),
        fdlManagement: state.auth.user.getIn(["role", "fdl_management"], false),
        theme: state.auth.user.getIn(["userData", "config", 'theme'], Map({})),

    };
};

const mapDispatchToProps = dispatch => {
    return {
        authWithToken: (token) => dispatch(authWithToken(token)),
        offlineNotification: (...args) => dispatch(newNotification(...args)),
        closeOfflineNotification: (key) => dispatch({type: CLOSE_SNACKBAR, key})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AxiosManager(ConnectionState(App), apiV2));