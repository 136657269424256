import React, {PureComponent} from "react"
import "./WorkData.scss"
import Travel from "./Travel/Travel"
import Purchase from "./Purchase/Purchase"
import {
    Autocomplete,
    ButtonBase,
    createFilterOptions,
    Divider,
    Fab,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import * as cMap from '../../../store/stateStr/CommMap'
import 'moment/locale/en-gb';
import {checkValidity, totCommHour} from "../../Library/Utils/Utils";
import {withMedia} from "react-media-query-hoc";
import {List} from "immutable";
import AddTaskIcon from "../../Library/Icons/AddTaskIcon";
import WtTask from "./WtTask/WtTask";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import WorkTime from "./WorkTime/WorkTime";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import CopyIcon from "../../Library/Icons/CopyIcon";
import PasteIcon from "../../Library/Icons/PasteIcon";
import {withTheme} from "@mui/styles";
import moment from "moment";
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';

const filterOptions = createFilterOptions({
    matchFrom: 'start', // stringify: option => option.title,
});

class WorkData extends PureComponent {

    state = {
        nc: {
            value: this.props.workdata.get(cMap.Nc), validation: {
                required: true, isNc: true
            }, valid: true,
        },
        fdl: {
            value: this.props.workdata.get(cMap.Fdl), validation: {
                required: true, isFdl: true
            }, valid: true,
        },
        clientNc: this.props.workdata.get(cMap.clientNc),
        region: this.props.workdata.get(cMap.Region),
        place: this.props.workdata.get(cMap.Luogo),
        interlocutor: this.props.workdata.get(cMap.Interlocutore),
        showTasks: false,
        fieldChanged: false
    }

    wtTaskRef = React.createRef();


    //
    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return !this.props.workdata.equals(nextProps.workdata) || this.state !== nextState;
    // }
    //
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (!this.props.workdata.equals(prevProps.workdata) && this.props.workdata.get(cMap.Nc) !== this.state.nc.value) {
    //         this.setState((prevState) => ({
    //             nc: {
    //                 ...prevState.nc,
    //                 value: this.props.workdata.get(cMap.Nc),
    //             }
    //         }))
    //     }
    // }

    showTask = () => {
        this.setState((oldState) => ({showTasks: !oldState.showTasks}))
    }

    onNcChangeHandler = (event, new_value) => {
        if (event) {
            this.setState((prevState) => ({
                nc: {
                    ...prevState.nc, value: new_value, valid: checkValidity(new_value, this.state.nc.validation),
                }
            }))
        }
    }

    onFdlChangeHandler = (event, new_value) => {
        if (event) {
            this.setState((prevState) => ({
                fdl: {
                    ...prevState.fdl, value: new_value, valid: checkValidity(new_value, this.state.fdl.validation),
                }
            }))
        }
    }

    render() {
        // Renderizzazione Viaggi
        let travel = null;
        let travelTot = 0;
        if (this.props.workdata.get(cMap.Travels)?.size > 0 && (this.props.workdata.get(cMap.Trs) || this.props.workdata.get(cMap.Fs))) {
            travel = this.props.workdata.get(cMap.Travels).map((t, index) => {
                if (t != null) {
                    travelTot += Math.round(totCommHour(t.get('start'), t.get('end')) * 100) / 100
                    return (<Travel
                        key={t.get('id')}
                        id_comm={this.props.workdata.get('id')}
                        travel={t}
                        idx_day={this.props.idx_day}
                        idx_comm={this.props.idx_comm}
                        idx_trav={index}
                        AddTravel={this.props.AddTravel}
                        RemoveTravel={this.props.RemoveTravel}
                        onPropsTravelChangeHandler={this.props.onPropsTravelChangeHandler}
                        machines={this.props.machines}
                        lockedData={this.props.lockedData >= 20}
                    />);
                }
            });
        }

        // Renderizzazione pagamenti
        let _purchases = null;
        if (this.props.workdata.get(cMap.Purch)?.size > 0 && (this.props.workdata.get(cMap.Trs) || this.props.workdata.get(cMap.Fs))) {
            _purchases = this.props.workdata.get(cMap.Purch).map((_purchase, index) => {
                if (_purchase != null) {
                    return (<Purchase
                        key={_purchase.get('id')}
                        id_comm={this.props.workdata.get('id')}
                        purch={_purchase}
                        idx_day={this.props.idx_day}
                        idx_comm={this.props.idx_comm}
                        idx_purch={index}
                        AddPurch={this.props.AddPurch}
                        RemovePurch={this.props.RemovePurch}
                        onPropsPurchChangeHandler={this.props.onPropsPurchChangeHandler}
                        lockedData={this.props.lockedData >= 20}
                    />);
                }
            });
        }

        // Renderizzazione Task
        let _tasks = null;
        if (this.props.workdata.get(cMap.CommTasks)?.size > 0) {
            _tasks = this.props.workdata.get(cMap.CommTasks).map((t, index) => {
                if (t != null) {
                    return (<WtTask
                        key={t.get('id')}
                        id={`wtTask${t.get('id')}`}
                        refCmp={this.wtTaskRef}
                        idWt={this.props.workdata.get('id')}
                        task={t}
                        idxDay={this.props.idx_day}
                        idxWt={this.props.idx_comm}
                        idxTask={index}
                        onWtTaskAdd={this.props.onWtTaskAdd}
                        onWtTaskDelete={this.props.onWtTaskDelete}
                        onWtTaskChangeHandler={this.props.onWtTaskChangeHandler}
                        lockedData={this.props.lockedData >= 20}
                    />);
                }
            });
        }

        // Renderizzazione WorkTime
        let _worktime = null;
        let _worktimeTot = 0;
        if (this.props.workdata.get('worktime').size > 0) {
            _worktime = this.props.workdata.get('worktime')
                // .sort((a, b) => {
                //     console.debug(a.get('start'))
                //     if (a.get('start') === '00:00:00' && a.get('end') === '00:00:00'){
                //         console.debug('zero')
                //         return +1
                //     }
                //     if (a.get('start') < b.get('start')) {
                //         console.debug('a')
                //         return -1;
                //     }
                //     if (a.get('start') > b.get('start')) {
                //         console.debug('b')
                //         return 1;
                //     }
                //     if (a.get('start') === b.get('start')) {
                //         console.debug('c')
                //         return 0;
                //     }
                // })
                .map((wt, index) => {
                    if (wt != null) {
                        _worktimeTot += Math.round(totCommHour(wt.get('start'), wt.get('end')) * 100) / 100
                        return (<WorkTime
                            key={wt.get('id')}
                            id={`wtTask${wt.get('id')}`}
                            idWd={this.props.workdata.get('id')}
                            data={wt}
                            idxDay={this.props.idx_day}
                            idxWd={this.props.idx_comm}
                            idxWt={index}
                            onWorkTimeAdd={this.props.onWorkTimeAdd}
                            onWorkTimeDelete={this.props.onWorkTimeDelete}
                            onWorkTimeChange={this.props.onWorkTimeChange}
                            lockedData={this.props.lockedData >= 20}
                        />);
                    }
                });
        }

        // Comandi aggiunta rimozione commessa
        const commCmd = (<React.Fragment>
            <Tooltip title={'Aggiungi commessa'}>
                <div>
                    <Fab
                        style={{backgroundColor: this.props.theme.palette.secondary.main, marginRight: '10px'}}
                        color={"secondary"}
                        className={"circButton"}
                        disabled={this.props.lockedData >= 20}
                        onClick={() => this.props.onWDAdd(this.props.idx_day, this.props.workdata.get(cMap.Day))}>
                        <AddIcon/>
                    </Fab>
                </div>
            </Tooltip>
            <Tooltip title={'Cancella commessa'}>
                <div>
                    <Fab
                        style={{backgroundColor: this.props.theme.palette.secondary.main}}
                        color={"secondary"}
                        className={"circButton"}
                        onClick={() => this.props.RemoveComm(this.props.idx_day, this.props.idx_comm, this.props.workdata.get(cMap.DbKey), this.props.workdata.get('locked'))}
                        disabled={this.props.lockRemoveComm || this.props.lockedData >= 20}>
                        <RemoveIcon/>
                    </Fab>
                </div>
            </Tooltip>
        </React.Fragment>)

        // Ricerca nome cliente, commessa e integrazione per visualizzazione note
        let cliName = null
        let descrComm = null
        let descrIntg = null
        let cliIdx
        let commIdx
        let intgIdx
        if (this.state.nc.valid && this.props.mngData) {
            cliIdx = this.props.mngData.findIndex(c => c.get('client_code') === this.state.nc.value.slice(0, 4))
            commIdx = this.props.mngData.getIn([cliIdx, 'commissions'], List([]))?.findIndex(c => c.get('commission_code') === this.state.nc.value.slice(4, 8))
            intgIdx = this.props.mngData.getIn([cliIdx, 'commissions', commIdx, 'integrations'], List([]))?.findIndex(i => i.get('integration_code') == this.state.nc.value.slice(9, 13))
            if (cliIdx >= 0) {
                cliName = this.props.mngData.getIn([cliIdx, 'company_name'])
            }
            if (commIdx >= 0) {
                descrComm = this.props.mngData.getIn([cliIdx, 'commissions', commIdx, 'description'])

                if (intgIdx >= 0) {
                    descrIntg = this.props.mngData.getIn([cliIdx, 'commissions', commIdx, 'integrations', intgIdx, 'description'])
                }
            }
        }

        // Calcolo altezza wt task
        if (this.wtTaskRef.current) {
            console.debug(this.wtTaskRef.current.clientHeight)
        }
        const wtTaskHeight = this.wtTaskRef.current ? this.wtTaskRef.current.clientHeight : 50;

        return (<>
            <ContextMenuTrigger id={`workdataContextMenu${this.props.workdata.get(cMap.DbKey)}`}>
                <Paper className={`${this.props.className} workData`}>
                    <div className={'rows'}>
                        <div className={'row'}>
                            <Autocomplete
                                id={'commissionTextField'}
                                className={'textfield'}
                                disabled={this.props.lockedData >= 20}
                                freeSolo
                                openOnFocus
                                autoHighlight
                                autoComplete
                                options={this.props.listWorkedCommissions?.sortBy(wc => moment(wc.get('last_day'), 'DD-MM-YYYY')).map(wc => wc.get('client_code') + wc.get('commission_code') + '_' + wc.get('integration_code')).reverse().toJS()}
                                inputValue={this.state.nc.value ? this.state.nc.value : ''}
                                onInputChange={(event, newInputValue) => {
                                    this.setState({fieldChanged: true})
                                    this.onNcChangeHandler(event, newInputValue)
                                }}
                                renderOption={(props, option, state) => {
                                    const wc = this.props.listWorkedCommissions.find(wc => wc.get('client_code') + wc.get('commission_code') + '_' + wc.get('integration_code') === option)
                                    return <div {...props} style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        alignItems: 'flex-start',
                                        padding: 0
                                    }}>
                                        <li style={{marginInline: '10px', marginTop: '10px'}}>
                                            <strong>Commessa: </strong>{wc.get('client_code') + wc.get('commission_code') + '_' + wc.get('integration_code')}
                                        </li>
                                        <li style={{marginInline: '10px'}}>
                                            <strong>Descrizione: </strong>{wc.get('description')}</li>
                                        <Divider variant={"fullWidth"} flexItem style={{marginBottom: '0px'}}/>

                                    </div>
                                }}
                                renderInput={params => (<TextField
                                    {...params}
                                    color={"secondary"}
                                    // className="textfield"
                                    style={{margin: '0'}}
                                    id={"Nc-" + this.props.workdata.get(cMap.DbKey)}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    error={!this.state.nc.valid && this.state.nc.value ? this.state.nc.value.length > 0 : false}
                                    label={this.state.nc.value.substring(0, 8) === '90001501' ? 'Commessa ♫♥' : "Commessa"}
                                    type="text"
                                    onBlur={(event) => {
                                        if (!this.state.fieldChanged) return
                                        this.setState({fieldChanged: false})
                                        if (this.state.nc.valid || this.state.nc.value.length === 0)
                                            this.props.onPropsCommChangeHandler(this.state.nc.value, this.props.idx_day, this.props.idx_comm, cMap.Nc, this.props.workdata.get(cMap.DbKey))
                                        const clientNc = this.props.mngData.getIn([cliIdx, 'commissions', commIdx, 'integrations', intgIdx, 'client_comm'])
                                        if (this.state.clientNc === '' && clientNc !== null && clientNc !== '' && clientNc !== undefined && this.state.nc.value !== '' && this.state.nc.value !== undefined) {
                                            this.setState({clientNc: clientNc})
                                            this.props.onPropsCommChangeHandler(clientNc, this.props.idx_day, this.props.idx_comm, cMap.clientNc, this.props.workdata.get(cMap.DbKey))
                                        }
                                    }}
                                />)}
                            />
                            <TextField
                                id={"NcCliente - " + this.props.workdata.get(cMap.DbKey)}
                                disabled={this.props.lockedData >= 20}
                                variant="outlined"
                                label="Commessa cliente"
                                color={"secondary"}
                                margin="dense" size="small"
                                className="textfield"
                                type="text"
                                // placeholder="Commissions cliente"
                                // value={this.props.workdata.get(cMap.clientNc)}
                                value={this.state.clientNc}
                                onChange={(event) => this.setState({clientNc: event.target.value, fieldChanged: true})}
                                onBlur={(event) => {
                                    if (!this.state.fieldChanged) return
                                    this.setState({fieldChanged: false})
                                    this.props.onPropsCommChangeHandler(this.state.clientNc, this.props.idx_day, this.props.idx_comm, cMap.clientNc, this.props.workdata.get(cMap.DbKey))
                                }}
                            />

                            <Autocomplete
                                id={'fdlTextField'}
                                className={'textfield'}
                                disabled={this.props.lockedData >= 20}
                                freeSolo
                                openOnFocus
                                autoHighlight
                                autoComplete
                                options={this.props.listUserFdl?.sortBy(f => moment(f.get('emission'), 'DD-MM-YYYY')).map(f => f.get('code')).reverse().toJS()}
                                inputValue={this.state.fdl.value ? this.state.fdl.value : ''}
                                onInputChange={(event, newInputValue) => {
                                    this.setState({fieldChanged: true})
                                    this.onFdlChangeHandler(event, newInputValue)
                                }}
                                renderOption={(props, option, state) => {
                                    const fdl = this.props.listUserFdl.find(f => f.get('code') === option)
                                    return <div {...props} style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        alignItems: 'flex-start',
                                        padding: 0
                                    }}>
                                        <li style={{marginInline: '10px', marginTop: '10px'}}>
                                            <strong>FDL: </strong>{fdl.get('code')}</li>
                                        <li style={{marginInline: '10px'}}>
                                            <strong>Commessa: </strong>{fdl.get('client_code') + fdl.get('commission_code') + '_' + fdl.get('integration_code')}
                                        </li>
                                        <li style={{marginInline: '10px'}}>
                                            <strong>Descrizione: </strong>{fdl.get('description')}</li>
                                        <Divider variant={"fullWidth"} flexItem style={{marginBottom: '0px'}}/>
                                    </div>
                                }}
                                // inputValue={this.props.workdata.get(cMap.Fdl)}
                                // onInputChange={(event, newInputValue) => this.onNcChangeHandler(event, newInputValue)}
                                renderInput={params => (<TextField
                                    {...params}
                                    color={"secondary"}
                                    // className="textfield"
                                    style={{margin: '0'}}
                                    id={"Fdl-" + this.props.workdata.get(cMap.DbKey)}
                                    variant="outlined"
                                    margin="dense" size="small"
                                    // error={!this.state.nc.valid && this.state.nc.value ? this.state.nc.value.length > 0 : false}
                                    label={'FDL'}
                                    type="text"
                                    helperText={!this.state.fdl.valid && this.state.fdl.value ? 'Formato: xxxxxx-pp' : ''}
                                    error={!this.state.fdl.valid && this.state.fdl.value ? this.state.fdl.value.length > 0 : false}
                                    onBlur={() => {
                                        if (!this.state.fieldChanged) return
                                        this.setState({fieldChanged: false})
                                        this.props.onPropsCommChangeHandler(this.state.fdl.value, this.props.idx_day, this.props.idx_comm, cMap.Fdl, this.props.workdata.get(cMap.DbKey))
                                            .then(res => {
                                                if (res.get('nc') || res.get('place') || res.get('region') || res.get('interlocutor')) {
                                                    this.setState((prevState) => {
                                                        let newState = {}
                                                        if (res.get('nc')) {
                                                            newState = {
                                                                nc: {
                                                                    ...prevState.nc, value: res.get('nc'), valid: true,
                                                                }
                                                            }
                                                        }
                                                        if (res.get('place')) {
                                                            newState = {
                                                                ...newState,
                                                                place: res.get('place')
                                                            }
                                                        }
                                                        if (res.get('region')) {
                                                            newState = {
                                                                ...newState,
                                                                region: res.get('region')
                                                            }
                                                        }
                                                        if (res.get('interlocutor')) {
                                                            newState = {
                                                                ...newState,
                                                                interlocutor: res.get('interlocutor')
                                                            }
                                                        }
                                                        return newState
                                                    })
                                                }
                                                if (res.get('client_nc')) {
                                                    this.setState((prevState) => ({clientNc: res.get('client_nc')}))
                                                }
                                            })
                                    }}
                                />)}
                            />
                            {/*<TextField id={"Fdl-" + this.props.workdata.get(cMap.DbKey)}*/}
                            {/*           variant="outlined"*/}
                            {/*           margin="dense" size="small"*/}
                            {/*           color={"secondary"}*/}
                            {/*           className="textfield"*/}
                            {/*           label="FDL"*/}
                            {/*           type="text"*/}
                            {/*           defaultValue={this.props.workdata.get(cMap.Fdl)} //className="inputCommessa data"*/}
                            {/*           onBlur={(event) => {*/}
                            {/*               this.props.onPropsCommChangeHandler(event.target.value, this.props.idx_day, this.props.idx_comm, cMap.Fdl, this.props.workdata.get(cMap.DbKey))*/}
                            {/*           }}*/}
                            {/*/>*/}
                            {(this.props.media.desktop || this.props.media.largeDesktop || this.props.media.tablet) && !this.props.hideCommCmd &&
                                <div className={'subRow'} style={{
                                    marginLeft: 'auto', marginRight: '0', flex: '.2', justifyContent: 'flex-end'
                                }}>
                                    {commCmd}
                                </div>}
                        </div>
                        <Typography className="commDescr">
                            {`${cliName ? cliName : ''} ${(cliName && descrComm) ? '-' : ''} ${descrIntg ? descrIntg : ''}`}
                        </Typography>

                        {_worktime}
                        <div className={'row'}>
                            <TextField
                                id={"Luogo-" + this.props.workdata.get(cMap.DbKey)}
                                variant="outlined"
                                color={"secondary"}
                                disabled={this.props.lockedData >= 20}
                                label="Luogo"
                                margin="dense" size="small"
                                className="textfield textfieldMaxWidth"
                                // style={{width: '275px'}}
                                type="text"
                                value={this.state.place}
                                onChange={(e) => {
                                    this.setState({place: e.target.value, fieldChanged: true})
                                }}
                                onBlur={() => {
                                    if (!this.state.fieldChanged) return
                                    this.setState({fieldChanged: false})
                                    this.props.onPropsCommChangeHandler(this.state.place, this.props.idx_day, this.props.idx_comm, cMap.Luogo, this.props.workdata.get(cMap.DbKey))
                                }}
                            />
                            <Autocomplete
                                className="textfield textfieldMaxWidth"
                                options={this.props.countries.map(c => c.get('name')).sort((a, b) => {
                                    if (a === 'Italia') {
                                        return -1;
                                    }
                                    if (a.toLowerCase() < b.toLowerCase()) {
                                        return -1;
                                    }
                                    if (a.toLowerCase() > b.toLowerCase()) {
                                        return 1;
                                    }
                                    if (a.toLowerCase() === b.toLowerCase()) {
                                        return 0;
                                    }
                                }).toJS()}
                                value={this.state.region}
                                filterOptions={filterOptions}
                                onInputChange={(event, newInputValue) => {
                                    this.setState({region: newInputValue, fieldChanged: true})
                                }}
                                disabled={this.props.lockedData >= 20}
                                renderInput={params => (<TextField
                                    {...params}
                                    color={"secondary"}
                                    style={{margin: '0'}}
                                    id={"region-" + this.props.workdata.get(cMap.DbKey)}
                                    variant="outlined"
                                    margin="dense" size="small"
                                    label={'Stato'}
                                    type="text"
                                    onBlur={(event) => {
                                        if (!this.state.fieldChanged) return
                                        this.setState({fieldChanged: false})
                                        this.props.onPropsCommChangeHandler(this.state.region, this.props.idx_day, this.props.idx_comm, cMap.Region, this.props.workdata.get(cMap.DbKey))
                                    }}
                                />)}
                            />
                            <TextField
                                id={"interlocutore-" + this.props.workdata.get(cMap.DbKey)}
                                variant="outlined"
                                label="Interlocutore"
                                color={"secondary"}
                                disabled={this.props.lockedData >= 20}
                                margin="dense" size="small"
                                className="textfield textfieldMaxWidth"
                                // style={{width: '275px'}}
                                type="text"
                                value={this.state.interlocutor}
                                onChange={(e) => {
                                    this.setState({interlocutor: e.target.value, fieldChanged: true})
                                }}
                                onBlur={(event) => {
                                    if (!this.state.fieldChanged) return
                                    this.setState({fieldChanged: false})
                                    this.props.onPropsCommChangeHandler(this.state.interlocutor, this.props.idx_day, this.props.idx_comm, cMap.Interlocutore, this.props.workdata.get(cMap.DbKey))
                                }}
                            />
                        </div>

                        <div className={'row'}>

                            <TextField
                                id={"Note-" + this.props.workdata.get(cMap.DbKey)}
                                variant="outlined"
                                color={"secondary"}
                                disabled={this.props.lockedData >= 20}
                                label="Note"
                                margin="dense" size="small"
                                className="textFieldNote"
                                type="text"
                                multiline
                                maxRows={1}
                                defaultValue={this.props.workdata.get(cMap.Note)}
                                onChange={() => {
                                    this.setState({fieldChanged: true})
                                }}
                                onBlur={(event) => {
                                    if (!this.state.fieldChanged) return
                                    this.setState({fieldChanged: false})
                                    this.props.onPropsCommChangeHandler(event.target.value, this.props.idx_day, this.props.idx_comm, cMap.Note, this.props.workdata.get(cMap.DbKey))
                                }}
                            />
                            <div className={'subRow'} style={{flex: '0.5'}}>
                                <ToggleButtonGroup
                                    className={'fsTrs'}
                                    value={this.props.workdata.get(cMap.Trs) ? 'trs' : this.props.workdata.get(cMap.Fs) ? 'fs' : ''}
                                    exclusive
                                    onChange={(e, newAlignment) => {
                                        if (!newAlignment) {
                                            this.props.onPropsCommChangeHandler(false, this.props.idx_day, this.props.idx_comm, cMap.Fs, this.props.workdata.get(cMap.DbKey));
                                            this.props.onPropsCommChangeHandler(false, this.props.idx_day, this.props.idx_comm, cMap.Trs, this.props.workdata.get(cMap.DbKey));
                                        } else if (newAlignment === 'fs') {
                                            this.props.onPropsCommChangeHandler(true, this.props.idx_day, this.props.idx_comm, newAlignment, this.props.workdata.get(cMap.DbKey));
                                            this.props.onPropsCommChangeHandler(false, this.props.idx_day, this.props.idx_comm, cMap.Trs, this.props.workdata.get(cMap.DbKey));
                                        } else if (newAlignment === 'trs') {
                                            this.props.onPropsCommChangeHandler(true, this.props.idx_day, this.props.idx_comm, newAlignment, this.props.workdata.get(cMap.DbKey));
                                            this.props.onPropsCommChangeHandler(false, this.props.idx_day, this.props.idx_comm, cMap.Fs, this.props.workdata.get(cMap.DbKey));
                                        }
                                    }}
                                    color={'secondary'}
                                    size={"small"}
                                    disabled={this.props.lockedData >= 20}
                                >
                                    <ToggleButton value="fs" aria-label="left aligned" sx={{width: 50}}>
                                        FS
                                    </ToggleButton>
                                    <ToggleButton value="trs" aria-label="centered" sx={{width: 50}}>
                                        Trs
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <div style={{marginLeft: 'auto', marginRight: '0', display: 'flex'}}>
                                    {this.props.user.getIn(['userData', 'config', 'tasks']) &&
                                        <Tooltip title={'Aggiungi task'}>
                                            <>
                                                <Fab className={"circButton"}
                                                     disabled={this.props.lockedData >= 20}
                                                     color={"secondary"}
                                                    // disabled={!(this.props.workdata.get(cMap.Trs) || this.props.workdata.get(cMap.Fs))}
                                                     onClick={() => this.props.onWtTaskAdd(this.props.idx_day, this.props.idx_comm, this.props.workdata.get(cMap.DbKey))}
                                                ><AddTaskIcon/>
                                                </Fab>
                                            </>
                                        </Tooltip>}
                                    <Tooltip title={'Aggiungi pagamento'}>
                                        <>
                                            <Fab
                                                className={"circButton"}
                                                color={"secondary"}
                                                disabled={!(this.props.workdata.get(cMap.Trs) || this.props.workdata.get(cMap.Fs)) || this.props.lockedData >= 20}
                                                onClick={() => this.props.AddPurch(this.props.idx_day, this.props.idx_comm, this.props.workdata.get(cMap.Day), this.props.workdata.get(cMap.DbKey))}>
                                                <CreditCardIcon/>
                                            </Fab>
                                        </>
                                    </Tooltip>
                                    <Tooltip title={'Aggiungi viaggio'}>
                                        <>
                                            <Fab
                                                className={"circButton"}
                                                color={"secondary"}
                                                disabled={!(this.props.workdata.get(cMap.Trs) || this.props.workdata.get(cMap.Fs)) || this.props.lockedData >= 20}
                                                onClick={() => this.props.AddTravel(this.props.idx_day, this.props.idx_comm, this.props.workdata.get(cMap.Day), this.props.workdata.get(cMap.DbKey))}>
                                                <EmojiTransportationIcon/>
                                            </Fab>
                                        </>
                                    </Tooltip>
                                    {(this.props.media.mobile || this.props.media.littleScreen) && !this.props.hideCommCmd && commCmd}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.props.workdata.get(cMap.CommTasks).size > 0 && <div className={'tasks'}
                                                                              style={{height: this.state.showTasks ? this.props.workdata.get(cMap.CommTasks).size * wtTaskHeight + 20 : 20}}>
                        <ButtonBase className={'taskShowButton'}
                                    onClick={this.showTask}>
                            <Divider variant={"middle"} style={{margin: '0 0 0 0', flex: 1}}/>
                            <Typography
                                style={{margin: '0 10px 0 10px'}}> {this.props.workdata.get(cMap.CommTasks).size} Task</Typography>
                            {this.state.showTasks ? <ArrowDropUpRoundedIcon/> : <ArrowDropDownRoundedIcon/>}
                        </ButtonBase>
                        {_tasks}
                    </div>}
                    {_purchases}
                    {travel}
                    <Divider variant={"fullWidth"} style={{marginTop: 10}}/>
                    <div className={'lockBar'}>
                        <div className={'start'}>
                            <Typography
                                className={'text'}>{`Tot. ore lavoro ${_worktimeTot}`}{travelTot > 0 && ` - Tot. ore viaggio ${travelTot}`}</Typography>
                        </div>
                        <div className={'end'}>
                            {this.props.lockedData >= 20 &&
                                <>

                                    {/*<Button*/}
                                    {/*    variant={"contained"}*/}
                                    {/*    // disabled={this.props.lockedData < 10}*/}
                                    {/*    // onClick={() => this.props.onForceEdit(this.props.workdata)}*/}
                                    {/*    startIcon={*/}
                                    {/*        <Tooltip*/}
                                    {/*            title={"L'amministrazione ha exportato questi dati, di conseguenza la modifica non può essere effettuata direttamente ma deve prima essere approvata"}>*/}
                                    {/*            <InfoRoundedIcon/>*/}
                                    {/*        </Tooltip>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    Richiesta modifica*/}
                                    {/*</Button>*/}
                                </>
                            }
                            {this.props.lockedData >= 10 && this.props.lockedData < 20 && <Tooltip
                                title={"Dati confermati per l'amministrazione, " +
                                    "l'amministrazione può exportare i seguenti dati"}
                            >
                                <div>
                                    Dati confermati
                                    <DoneAllRoundedIcon/>
                                </div>
                            </Tooltip>}
                            {/*{this.props.lockedData >= 20 && <Tooltip*/}
                            {/*    title={"L'amministrazione ha exportato e bloccato questi dati"}*/}
                            {/*>*/}
                            {/*    <div>*/}
                            {/*        Dati bloccati*/}
                            {/*        <DoneAllRoundedIcon/>*/}
                            {/*    </div>*/}
                            {/*</Tooltip>}*/}
                        </div>

                    </div>
                </Paper>
            </ContextMenuTrigger>
            {(typeof this.props.onCopy !== "undefined" || typeof this.props.onPaste !== "undefined") ?
                <ContextMenu id={`workdataContextMenu${this.props.workdata.get(cMap.DbKey)}`}>
                    {typeof this.props.onCopy !== "undefined" ?
                        <MenuItem data={{id: this.props.workdata.get(cMap.DbKey)}}
                                  onClick={(e, d) => this.props.onCopy(this.props.idx_day, this.props.idx_comm)}

                        >
                            <CopyIcon className="copy"/>
                            <span>Copy</span>
                        </MenuItem> : ''}
                    {typeof this.props.onPaste !== "undefined" ?
                        <MenuItem onClick={() => this.props.onPaste(this.props.idx_day, this.props.idx_comm)}>
                            <PasteIcon className="paste"/>
                            <span>Paste</span>
                        </MenuItem> : ''}
                </ContextMenu> : ''}
        </>)
    }

}


export default withTheme(withMedia(WorkData));
