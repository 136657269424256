import React from 'react'
import {Button, Paper, Tooltip} from "@mui/material";
import style from './WorkToolbar.module.scss'
import {useDispatch} from "react-redux";
import {onWDAdd} from "../../../store/actions/WorkData";
import Typography from "@mui/material/Typography";
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';

const WorkToolbar = ({idxDay, day, lockedDay, lastDay, paidLeaveInserted}) => {
    const dispatch = useDispatch()

    return <Paper className={style.workToolbar} style={{
        backgroundColor: lastDay ? '#c62828' : '#00000000',
        backgroundImage: lastDay ? 'unset' : ''
    }}>
        <div className={style.left}>
            {/*<Tooltip title={"Rendi disponibile i dati all'amministrazione"}>*/}
                {/*<Button*/}
                {/*    variant={"contained"}*/}
                {/*    className={style.button}*/}
                {/*    disabled={lockedDay}*/}
                {/*    style={{marginRight: 25}}*/}
                {/*>*/}
                {/*    Conferma giorno*/}
                {/*</Button>*/}
            {/*</Tooltip>*/}
            <Tooltip title={'Segna giorno come ferie'}>

                <Button
                    variant={"contained"}
                    className={style.button}
                    disabled={paidLeaveInserted || lockedDay}
                    onClick={() => dispatch(onWDAdd(idxDay, day, 0,2))}
                >
                    Ferie
                </Button>
            </Tooltip>
            <Tooltip title={'Aggiungi permesso'}>
                <Button
                    variant={"contained"}
                    className={style.button}
                    disabled={paidLeaveInserted || lockedDay}
                    onClick={() => dispatch(onWDAdd(idxDay, day, 0, 1))}
                >
                    Permesso
                </Button>
            </Tooltip>

        </div>
        {lastDay &&
            <div style={{display:'flex'}}>
                <NewReleasesRoundedIcon style={{marginRight:'10px'}}/>
                <Typography>Ultimo giorno del mese, caricare tutte le ore</Typography>
            </div>
        }
        <div className={style.right}>
            <Button
                variant={'contained'}
                onClick={() => dispatch(onWDAdd(idxDay, day))}
                disabled={lockedDay}
            >
                Aggiungi commessa
            </Button>
        </div>
    </Paper>
}

export default WorkToolbar;